
.App-logo {
  margin-top: -10vw;
  height: 60vw;
  pointer-events: none;
  position: absolute;
}
.logo-name{
  margin-top: 25vw;
  font-family: "gulax";
  font-size: 4vw;
}

.logo2 {
  margin-top: 19vh;
  margin-left: 70vw;
  pointer-events: none;
  position: absolute;

}
.logo-2{
  height: 60vmin;
}
.logo3 {
  margin-top: 97vh;
  pointer-events: none;
  position: absolute;

}
.logo-3{
  height: 60vmin;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }  
  .logo-2 {
    animation: App-logo-spin infinite 20s linear;
  }
  .logo-3 {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2{
  font-family: 'gulax';
  font-size: 3vw;
}
h3{
  font-family: 'gulax';
  font-size: 1.7vw;
}
.landing-text{
  width: 50vw;
  margin-left: 25vw;
  font-size: 1.3vw;
  text-align: center;

}

.landing-visie{
  margin-top: 15vw;
  width: 50vw;
  margin-left: 25vw;
  font-size: 1.3vw;
}
.landing-missie{
  margin-top: 15vw;
  width: 50vw;
  margin-left: 25vw;
  font-size: 1.3vw;
}
.landing-contact{
  text-align: center;
  margin-top: 15vw;
  width: 50vw;
  margin-left: 25vw;
  font-size: 1.3vw;
  margin-bottom: 10vh;
}
.landing-vacatuur{
  display: none;
  margin-top: 15vw;
  width: 50vw;
  margin-left: 25vw;
  font-size: 1.3vw;
}
.landing-folder{
  margin-top: 15vw;
  width: 50vw;
  margin-left: 25vw;
  font-size: 1.3vw;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.landing-folder__button{
  margin-top: 5vw;
  padding: 1vw 2vw;
  border-radius: 20px;
  border-color: red;
  border-width: 5px;
  border-style: solid;
  width: fit-content;
  background-color: yellow;
}
.landing-folder__button a{
  color: red!important;
  font-family: 'EB Garamond', serif;
  font-style: italic;

}
.landing-folder__button:hover{
  margin-top: 5vw;
  padding: 1vw 2vw;
  border-radius: 20px;
  border-color: yellow;
  border-width: 5px;
  border-style: solid;
  width: fit-content;
  background-color: red;
}
.landing-folder__button:hover a{
  color: yellow!important;
  font-family: 'EB Garamond', serif;
  font-style: italic;

}

.instagram_link{
  font-family: 'gulax';
  font-size: 3vw;
}

.landing-logos{
  width: 80vw;
  margin-left: 9vw;
  margin-top: 15vw;
  margin-bottom: 15vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.landing-logos img{
  position: relative;
  height: 6vw;
  padding: 1vw;
  
}


@font-face {
  font-family: 'gulax';
  src: url('./assets/fonts/gulax-webfont.woff2') format('woff2'),
       url('./assets/fonts/gulax-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
